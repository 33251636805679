import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bulma/css/bulma.min.css';
import config from '../../config';
import './Home.css'; // Import the CSS file

const Home = () => {
  const [selectedTitle, setSelectedTitle] = useState(null);
  const navigate = useNavigate();

  return (
    <div className="background-container">
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">

          {/* Add the title and description at the top */}
          <h1 className="title is-2 has-text-black has-text-left">
            Empowering Tomorrow
            <br />
            <span className="gradient-text">AI-Driven Enterprise Solutions</span> <br /> by Mesmerise
          </h1>
          
          <p className="is-size-5 has-text-black mt-4 has-text-left">
            Discover how our cutting-edge AI solutions can propel your business forward, 
            enhancing productivity, engagement, and compliance in today’s competitive market
            Our expert team specializes in harnessing the power of generative AI, providing
            tailored solutions that securely manage and curate data across diverse industries.
          </p>

          <p className="is-size-5 has-text-left has-text-weight-bold mt-6 mb-6" style={{ color: '#9C50A3' }}>
          Explore our latest demos and see how our AI-driven approach can transform your business.
          </p>


          {/* Displaying the items with tags */}
          <div className="columns is-multiline is-centered mb-6">
            {config.map((item, index) => (
              <div key={index} className="column is-half-mobile is-one-quarter-tablet is-one-quarter-desktop">
                <Link to={item.useIframe ? `/iframe/${encodeURIComponent(item.title)}` : item.url} className="no-decoration">
                  <div
                    data-id={item.id}
                    className={`box is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-stretch p-4 box-container ${selectedTitle === item.title ? 'selected' : ''}`}
                  >
                    <div className="content">
                      <h1 className="title is-size-5-tablet is-size-6-mobile has-text-left" style={{ color: '#9C50A3' }}>
                        {item.title}
                      </h1>
                      <div className="tags-wrapper">
                        {item.tags.map((tag, idx) => (
                          <span key={idx} className="tag-box is-size-7-mobile is-size-6-tablet">
                            {tag}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>




          <div className="has-text-centered mt-6 px-4">
            <p className="has-text-black is-size-4 has-text-weight-bold">
              Ready for more?
            </p>
            <p className="has-text-black is-size-5">
              Get in touch for a personalized demo with our team
            </p>
            <a
              href="https://mesmerisegroup.com/contact/"
              target="_blank"
              rel="noopener noreferrer"
              className="button is-medium"
            >
              Request a demo
            </a>
          </div>
        </div>
      </div>
      </section>

      <footer className="footer">
        <div className="content has-text-centered">
          <p style={{ margin: '0' }}>© Copyright Mesmerise Solutions UK Ltd 2024</p>
        </div>
      </footer>
    </div>

  );
};

export default Home;
