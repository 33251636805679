const config = [
  {
    id: 0,
    title: "Multi Virtual Assistant",
    tags: ["Productivity Boost", "Enhanced Engagement"],
    description: "Virtual Assistant with multiple verticles, refers to documents provided for answers",
    information: "We've diligently curated a wealth of knowledge...",
    url: "https://va.play.mesmerisegroup.com/",
    useIframe: true
  },
  {
    id: 1,
    title: "Aviation Maintenance",
    tags: ["Productivity Boost", "Risk & Compliance"],
    description: "Enabling junior engineers to easliy navigate through manuals",
    information: "Navigating the complex landscape of aviation...",
    url: "/aviation-maintenance",
    useIframe: false
  },
  {
    id: 2,
    title: "Medical Training",
    tags: ["Productivity Boost", "Risk & Compliance"],
    description: "Using RAG (Retrieval-Augmented Generation) to help junior doctors find solutions",
    information: "This is the API for a RAG API...",
    url: `${process.env.REACT_APP_MEDICALTRAINING_URI}` || process.env.REACT_APP_MEDICALTRAINING_URI,
    useIframe: true
  },
  {
    id: 3,
    title: "Fraud Detection",
    tags: ["Productivity Boost", "Enhanced Engagement"],
    description: "A tool to minimize false positives in money laundering detection",
    information: "Money laundering is the illegal process...",
    url: `${process.env.REACT_APP_FRAUDDETECTION_URI}` || process.env.REACT_APP_FRAUDDETECTION_URI,
    useIframe: true
  }
];

export default config;
