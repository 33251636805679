import axios from 'axios';

// Load environment variables
const apiUrl = process.env.REACT_APP_AVIATION_API_URL;
const apiKey = process.env.REACT_APP_AVIATION_API_KEY;

if (!apiUrl || !apiKey) {
    console.error("API URL or API Key is missing. Check your .env file.");
}

class AviationApi {
    constructor() {
        this.chatHistory = [];
    }

    // Function to ask a question to the API
    async ask(input) {
        const url = `${apiUrl}/post_input`;
        const headers = {
            'accept': 'application/json',
            'x-api-key': apiKey
        };
        const data = {
            input: input,
            chat_history: this.chatHistory
        };

        try {
            const response = await axios.post(url, data, { headers });
            if (response.status === 200) {
                this.chatHistory = response.data.chat_history;
                return response.data;
            } else {
                console.error(`Error: API request failed with status code ${response.status}`);
                return null;
            }
        } catch (error) {
            console.error("Error calling the API: ", error);
            return null;
        }
    }

    // Function to clear chat history
    clearHistory() {
        this.chatHistory = [];
    }

    // Function to generate a PDF URL
    async generatePdfUrl(chapter, start, end) {
        // Use chapter directly as the filename (already formatted correctly)
        const filename = chapter;

        const url = `${apiUrl}/create_pdf_url?filename=${filename}&start_page=${start}&end_page=${end}`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'accept': 'application/json',
                    'x-api-key': apiKey
                }
            });
            
            if (response.status === 200) {
                return response.data.pdf_sas_url;
            } else {
                console.error(`Error: API request failed with status code ${response.status}`);
                return null;
            }
        } catch (error) {
            console.error("Error generating PDF URL: ", error);
            return null;
        }
    }


    // Function to generate a URL of an existing PDF in blob storage
    async generateUrl(blobName) {
        const url = `${apiUrl}/get_pdf_url?filename=${blobName}`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'accept': 'application/json',
                    'x-api-key': apiKey
                }
            });
            if (response.status === 200) {
                return response.data.pdf_sas_url;
            } else {
                console.error(`Error: API request failed with status code ${response.status}`);
                return null;
            }
        } catch (error) {
            console.error("Error generating URL: ", error);
            return null;
        }
    }
}

export default new AviationApi();
