import { Text } from "@fluentui/react";
import { Delete24Regular } from "@fluentui/react-icons";
import styles from "./ClearChatButton.module.css";

const ClearChatButton = ({ className, disabled, onClick }) => {
    const handleClearChat = () => {
        if (onClick) {
            onClick();
        }
        window.dispatchEvent(new CustomEvent("clearChatEvent"));
    };

    return (
        <div 
            className={`${styles.container} ${className || ""} ${disabled ? styles.disabled : ""}`} 
            onClick={handleClearChat}
        >
            <Delete24Regular />
            <Text>{"Clear chat"}</Text>
        </div>
    );
};

export default ClearChatButton;
