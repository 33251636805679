import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from '../../config';
import './IframePage.css'; // Import the CSS file for styling

const IframePage = () => {
  const { title } = useParams();
  const [loadError, setLoadError] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [openTabFlag, setOpenTabFlag] = useState(false); // Flag to handle new tab opening
  const page = config.find(item => item.title === decodeURIComponent(title));

  useEffect(() => {
    let timer;

    if (page) {
      // Set a timeout to detect iframe loading issues
      timer = setTimeout(() => {
        if (!iframeLoaded && !openTabFlag) {
          setLoadError(true);
          window.open(page.url, '_blank');
          setOpenTabFlag(true); // Set flag to avoid reopening
        }
      }, 5000); // 5 seconds timeout

      return () => clearTimeout(timer); // Clear timeout on cleanup
    }
  }, [iframeLoaded, openTabFlag, page?.url]);

  const handleLoad = () => {
    setIframeLoaded(true);
    setLoadError(false); // Clear error on successful load
  };

  const handleError = () => {
    // We can't directly handle iframe errors, but setting the error state here
    if (!iframeLoaded) {
      setLoadError(true);
    }
  };

  return (
    <div className="iframe-container">
      {page ? (
        openTabFlag ? (
          <div className="box has-background-dark">
            <h1 className="title has-text-white">
              Please complete the process in the new tab that was opened.
            </h1>
          </div>
        ) : (
          <>
            {loadError ? (
              <div className="box has-background-dark">
                <h1 className="title has-text-white">
                  Can't open the page in an iframe. <a href={page.url} target="_blank" rel="noopener noreferrer">Click here</a> to view it in a new tab.
                </h1>
              </div>
            ) : (
              <iframe
                src={page.url}
                title={page.title}
                className="iframe-full"
                onLoad={handleLoad}
                onError={handleError} // Handling iframe errors
              />
            )}
          </>
        )
      ) : (
        <div className="box has-background-dark">
          <h1 className="title has-text-white">Page Not Found</h1>
        </div>
      )}
    </div>
  );
};

export default IframePage;
