export async function getTokenOrRefresh() {
    const speechKey = process.env.REACT_APP_SPEECH_KEY;
    const speechRegion = 'uksouth'

    if (!speechKey || !speechRegion) {
        console.error('SPEECH_KEY or SPEECH_REGION is missing in environment variables.');
        return { authToken: null, error: 'Missing environment variables' };
    }

    try {
        // Prepare headers for the token request
        const headers = {
            'Ocp-Apim-Subscription-Key': speechKey,
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        // Make the token request
        const response = await fetch(`https://${speechRegion}.api.cognitive.microsoft.com/sts/v1.0/issueToken`, {
            method: 'POST',
            headers: headers
        });

        if (!response.ok) {
            throw new Error('Failed to obtain token from Azure Speech Service');
        }

        // Get the token from the response
        const token = await response.text();

        return {
            authToken: token,
            region: speechRegion
        };
    } catch (error) {
        console.error('Error fetching speech token:', error);
        return { authToken: null, error: error.message };
    }
}
