import React, { useEffect, useRef, useState } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import { IconButton } from '@fluentui/react/lib/Button';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import ReferencesTaskList from '../ReferencesTaskList/ReferencesTaskList'; // Import ReferencesTaskList

// Initialize Fluent UI icons
initializeIcons();

// Set the worker src to a CDN for PDF.js
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`;

const PdfViewer = ({ pdfDetails }) => {
  const canvasRef = useRef(null);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPdfIndex, setSelectedPdfIndex] = useState(0); // Track the selected PDF
  const renderingRef = useRef(false);

  useEffect(() => {
    // Load the selected PDF whenever the selectedPdfIndex changes
    if (pdfDetails.length > 0) {
      const selectedPdf = pdfDetails[selectedPdfIndex];
      const loadingTask = getDocument(selectedPdf.pdfUrl || pdfDetails.pdfUrl);
      console.log(selectedPdf.pdfUrl)
      loadingTask.promise.then(
        (pdf) => {
          setPdfDoc(pdf);
          setTotalPages(pdf.numPages);
          setPageNum(1); // Reset to the first page
          renderPage(1, pdf);
        },
        (reason) => {
          console.error('Error loading PDF: ', reason);
        }
      );
    }
  }, [selectedPdfIndex, pdfDetails]);

  const renderPage = (num, pdf) => {
    if (!pdf || renderingRef.current) return;
    renderingRef.current = true;

    pdf.getPage(num).then((page) => {
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      // Set canvas dimensions
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      context.clearRect(0, 0, canvas.width, canvas.height);

      page.render(renderContext).promise.then(() => {
        renderingRef.current = false;
      });
    });
  };

  const handleNextPage = () => {
    if (pdfDoc && pageNum < totalPages) {
      setPageNum(pageNum + 1);
      renderPage(pageNum + 1, pdfDoc);
    }
  };

  const handlePrevPage = () => {
    if (pdfDoc && pageNum > 1) {
      setPageNum(pageNum - 1);
      renderPage(pageNum - 1, pdfDoc);
    }
  };

  return (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
      {pdfDetails.map((pdf, index) => (
          // Check if pdfName exists before rendering the button
          pdf.pdfName && (
            <button
              key={index}
              onClick={() => setSelectedPdfIndex(index)}
              style={{
                backgroundColor: selectedPdfIndex === index ? '#007bff' : '#ccc',
                color: selectedPdfIndex === index ? 'white' : 'black',
                padding: '10px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: selectedPdfIndex === index ? 'bold' : 'normal',
              }}
            >
              {pdf.pdfName}
            </button>
          )
        ))}
      </div>
  
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        {/* PDF Viewer Canvas */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column', // Stack the canvas and buttons vertically
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #000',
            overflow: 'auto',
            height: 'auto',
          }}
        >
          <canvas
            ref={canvasRef}
            style={{
              width: 'auto', // Maintain original width
              height: 'auto', // Maintain original height
              maxWidth: '100%', // Limit width to container width
              maxHeight: '80vh', // Prevent the canvas from exceeding 80% of the viewport height
            }}
          />
  
          {/* Page Navigation Buttons */}
          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
            }}
          >
            <IconButton
              iconProps={{ iconName: 'ChevronLeft' }}
              title="Previous Page"
              ariaLabel="Previous Page"
              onClick={handlePrevPage}
              disabled={pageNum <= 1}
            />
            <span style={{ fontSize: '16px' }}>
              Page {pageNum} of {totalPages}
            </span>
            <IconButton
              iconProps={{ iconName: 'ChevronRight' }}
              title="Next Page"
              ariaLabel="Next Page"
              onClick={handleNextPage}
              disabled={pageNum >= totalPages}
            />
          </div>
        </div>
  
        {/* References Task List (only shown when pdfTaskName is available) */}
        {pdfDetails[selectedPdfIndex]?.pdfTaskName && (
          <div
            style={{
              width: '40%',
              paddingLeft: '20px',
              overflowY: 'auto',
              minWidth: '40%',
            }}
          >
            <ReferencesTaskList
              pdfTaskPath={pdfDetails[selectedPdfIndex].pdfTaskPath}
              pdfTaskName={pdfDetails[selectedPdfIndex].pdfTaskName}
              currentPage={pageNum}
            />
          </div>
        )}
      </div>
    </div>
  );
  
};

export default PdfViewer;
