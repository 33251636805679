import React, { useState, useEffect } from 'react';
import aviationApi from '../../pages/AviationMaintenance/AviationApi';
import PDFViewer from '../PdfViewer/PdfViewer';

const ReferencesTaskList = ({ pdfTaskPath, pdfTaskName, currentPage }) => {
  const [references, setReferences] = useState([]);
  const [selectedReferenceLink, setSelectedReferenceLink] = useState(null);
  const [selectedReferenceName, setSelectedReferenceName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pdfDetails, setPdfDetails] = useState([]);  // New state for PDF details

  // Fetch references based on pdfTaskPath and pdfTaskName
  useEffect(() => {
    const fetchReferences = () => {
      if (pdfTaskPath && pdfTaskName) {
        // Get the references for the current page
        const referencesForPage = pdfTaskPath[currentPage] || [];
        const namesForPage = pdfTaskName[currentPage] || [];

        // Combine the paths and names into a list of references
        const combinedReferences = referencesForPage.map((taskPath, index) => ({
          referenceName: namesForPage[index] || 'Unknown',
          referenceLink: taskPath,
        }));

        setReferences(combinedReferences);
      }
    };

    fetchReferences();
  }, [pdfTaskPath, pdfTaskName, currentPage]); // Re-run when pdfTaskPath, pdfTaskName, or currentPage changes

  // Reset everything when the page or pdfTaskPath/pdfTaskName changes
  useEffect(() => {
    setSelectedReferenceLink(null);
    setSelectedReferenceName(null);
    setIsLoading(false);
    setPdfDetails([]);  // Reset pdfDetails when page or references change
  }, [currentPage, pdfTaskPath, pdfTaskName]);

  // Handle reference click to display the reference link or call API
  const handleReferenceClick = async (referenceLink, referenceName) => {
    setSelectedReferenceLink(referenceLink);
    setSelectedReferenceName(referenceName);

    // Check if the link ends with .pdf, if so, pass the link to the pdfDetails state
    if (referenceLink.endsWith('.pdf')) {
      setIsLoading(true); // Set loading state to true
      try {
        // Make the API call to generate the PDF URL
        const response = await aviationApi.generateUrl(referenceLink); 

        // Add the reference link to the pdfDetails state
        setPdfDetails([
          { pdfUrl: response},
        ]);
      } catch (error) {
        console.error('Error generating URL:', error);
      } finally {
        setIsLoading(false); // Set loading state to false after the operation
      }
    }
  };

  return (
    <div>
      {references.length === 0 ? (
        <p>No references available for this page.</p>
      ) : (
        <ul>
          {references.map((reference, index) => (
            <li
              key={index}
              onClick={() => handleReferenceClick(reference.referenceLink, reference.referenceName)}
              style={{
                padding: '10px',
                borderRadius: '5px',
                margin: '5px 0',
                maxWidth: '75%',
                wordWrap: 'break-word',
                cursor: reference.referenceLink.endsWith('.pdf') ? 'pointer' : 'not-allowed',
                backgroundColor: selectedReferenceLink === reference.referenceLink ? '#007bff' : '#ccc',
                color: selectedReferenceLink === reference.referenceLink ? 'white' : 'black',
                opacity: reference.referenceLink.endsWith('.pdf') ? 1 : 0.6, // Make it semi-transparent if not a PDF
                transition: 'background-color 0.3s, opacity 0.3s',
              }}
            >
              {reference.referenceName}
            </li>
          ))}
        </ul>
      )}

      {isLoading && (
        <div style={{ marginTop: '20px' }}>
          <h4>Generating PDF URL...</h4>
        </div>
      )}

      {/* Conditionally render the PDFViewer component when pdfDetails is available */}
      {pdfDetails.length > 0 && (
        <PDFViewer pdfDetails={pdfDetails} />
      )}
    </div>
  );
};

export default ReferencesTaskList;
