import React, { useState, useEffect, useRef } from "react";
import QuestionInput from '../../components/QuestionInput/QuestionInput';
import styles from "./AviationMaintenance.module.css";
import aviationApi from './AviationApi';
import PdfViewer from '../../components/PdfViewer/PdfViewer';

const AviationMaintenance = () => {
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(""); // New state for loading messages
    const [pdfDetails, setPdfDetails] = useState([]);
    const chatEndRef = useRef(null);  // Ref for scrolling to the end of the chat

    useEffect(() => {
        // Scroll to the bottom whenever the messages state changes
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]); // Dependency on messages to trigger scrolling
const handleSendMessage = async (question) => {
    setMessages((prevMessages) => [
        ...prevMessages,
        { text: question, sender: "user" }
    ]);

    setIsLoading(true);
    setLoadingMessage("Assistant responding..."); // Set loading message for the assistant response

    try {
        const response = await aviationApi.ask(question);

        if (response) {
            const formattedResponse = response.answer.replace(/\n/g, "/n");
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: formattedResponse, sender: "system" }
            ]);

            if (response.if_fic && response.if_fic.bool && response.if_fic.tool_response) {
                // Set loading message for the PDF loading
                setLoadingMessage("Loading PDF...");

                // Use Promise.all to resolve all asynchronous generatePdfUrl calls
                const pdfData = await Promise.all(response.if_fic.tool_response.map(async (pdf) => {
                    // Extract the necessary fields along with pdfName and pdfUrl
                    const pdfInfo = {
                        pdfName: pdf.task_number,
                        pdfUrl: await aviationApi.generatePdfUrl(pdf.filename, pdf.pages.start_page, pdf.pages.end_page),
                        pdfTaskPath: pdf.references_task_path,  // Add references_task_path
                        pdfTaskName: pdf.references_task_name,  // Add references_task_name
                    };
                    return pdfInfo;
                }));

                setPdfDetails(pdfData);
            }
        } else {
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: "Failed to get response from API.", sender: "system" }
            ]);
        }
    } catch (error) {
        setMessages((prevMessages) => [
            ...prevMessages,
            { text: "Error calling the API.", sender: "system" }
        ]);
    } finally {
        setIsLoading(false);
        setLoadingMessage(""); // Clear loading message after process is done
    }
};


    const handleClearChat = () => {
        aviationApi.clearHistory();
        setMessages([]);
        setPdfDetails([]);
        setLoadingMessage(""); // Clear loading message when chat is cleared
    };

    const renderMessage = (msg) => {
        // Split the message text based on /n
        const parts = msg.text.split(/\/n/).filter(Boolean);

        return (
            <>
                {parts.map((part, index) => {
                    // Check if the part is a numbered option (starts with number and dot)
                    if (/^\d+\./.test(part)) {
                        // Format the part to handle bold text (inside **)
                        const formattedPart = part.split(/\*\*(.*?)\*\*/).map((segment, i) =>
                            i % 2 === 1 ? (
                                <span key={`${index}-${i}`} style={{ fontWeight: "bold", color: "black" }}>
                                    {segment}
                                </span>
                            ) : (
                                <span key={`${index}-${i}`}>{segment}</span>
                            )
                        );

                        return (
                            <div
                                key={index}
                                onClick={() => handleSendMessage(part)} // Handle the click event
                                style={{
                                    display: "inline-block",
                                    padding: "10px",
                                    margin: "5px",
                                    borderRadius: "15px",
                                    backgroundColor: "#f0f0f0", // Light background for the box
                                    border: "1px solid #ccc", // Light border
                                    cursor: "pointer", // Make it clickable
                                    fontWeight: "bold",
                                    color: "black",
                                    maxWidth: "400px",  // Control width for the text box
                                    wordWrap: "break-word", // Break the words if they are too long
                                }}
                            >
                                {formattedPart}
                            </div>
                        );
                    }

                    // For non-numbered options, render them as regular text
                    const formattedPart = part.split(/\*\*(.*?)\*\*/).map((segment, i) =>
                        i % 2 === 1 ? (
                            <span key={`${index}-${i}`} style={{ fontWeight: "bold", color: "black" }}>
                                {segment}
                            </span>
                        ) : (
                            <span key={`${index}-${i}`}>{segment}</span>
                        )
                    );
                    return <div key={index}>{formattedPart}</div>;
                })}
            </>
        );
    };

    return (
        <div className="section has-background-white has-text-centered">
            <h1 className="title is-1 has-text-black">Aviation UX prep</h1>
            <div className={styles.chatContainer}>
                <div className={styles.chatBox}>
                    {messages.map((msg, index) => (
                        <div
                            key={index}
                            className={`${styles.messageBubble} ${msg.sender === "user" ? styles.userMessage : styles.systemMessage}`}
                        >
                            {renderMessage(msg)}
                        </div>
                    ))}
                    {isLoading && (
                        <div className={styles.loadingMessage}>{loadingMessage}</div> // Display dynamic loading message
                    )}
                    <div ref={chatEndRef} /> {/* This div will act as the scroll anchor */}
                </div>
                <QuestionInput
                    onSend={handleSendMessage}
                    disabled={false}
                    placeholder="Type your message..."
                    clearOnSend={true}
                    onClearChat={handleClearChat}
                />
            </div>
            {pdfDetails.length > 0 && (
                <PdfViewer pdfDetails={pdfDetails} />
            )}
        </div>
    );
};

export default AviationMaintenance;
